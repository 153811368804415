import React, { FC, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { useModalContext } from '~/contexts/modal/ModalContext';
import { AiResearcherPath } from '~/modules/tools/constants';
import { checkIsAiResearcherAccessible } from '~/modules/tools/views/AiResearcher/helpers/helpers';
import IntroModal from '~/modules/tools/views/AiResearcher/IntroModal';
import DateService from '~/services/Date';
import { useActions } from '~/store/hooks/useActions';
import { useAppSelector } from '~/store/hooks/useAppSelector';
import { colors } from '~/theme/colors';

type Props = {
  children?: React.ReactNode;
};

const AiResearcherIntroWrapper: FC<Props> = ({ children }) => {
  const profile = useAppSelector((state) => state.profile);

  const { updateUserExtraFields } = useActions();
  const { handleOpenModal } = useModalContext();
  const navigate = useNavigate();

  useEffect(() => {
    const isInTestGroup = checkIsAiResearcherAccessible(profile);

    const hasOneDayPassedSinceSignup =
      profile.extra_fields.sign_up_at &&
      Math.abs(
        DateService.differenceInDays(
          new Date(profile.extra_fields.sign_up_at),
          new Date(),
        ),
      ) >= 1;

    const isNotificationShown =
      profile.extra_fields?.notifications?.ai_researcher_intro_modal;

    if (
      !profile.isLoggedIn ||
      !profile.is_onboarded ||
      !isInTestGroup ||
      !hasOneDayPassedSinceSignup ||
      isNotificationShown
    ) {
      return;
    }

    handleOpenModal({
      disableBackdropClick: true,
      closeButtonColor: colors.neutral_30,
      onClose: () => {
        updateUserExtraFields({
          notifications: {
            ...profile.extra_fields?.notifications,
            ai_researcher_intro_modal: 1,
          },
        });
      },
      component: ({ onClose }) => (
        <IntroModal
          onSubmit={() => {
            navigate(AiResearcherPath);
            onClose();
          }}
          onRefuse={onClose}
        />
      ),
    });
  }, [profile]);

  return <>{children}</>;
};

export { AiResearcherIntroWrapper };
